<template>
    <div class="invite-page">
        <el-card class="basic-card banner-card">
            <div class="banner-wapper">
                <img class="banner-img" src="../../../static/images/account/invite-banner-img.png" alt="banner-img"/>
                <div class="banner-right">
                    <div class="fonts-24 text-weight-500">邀请好友，赢字符包</div>
                    <div class="margin-t-20 fonts-15 color-warning">好友成功注册后，你和好友都可获得5万字符</div>
                    <div class="margin-t-25">
                        <el-popover trigger="click" popper-class="invite-popover">
                            <div>
                                <el-input readonly size="medium" placeholder="请输入内容" v-model="shareLink" style="width:320px;">
                                    <el-button slot="append" @click="copyShareLink">复制邀请链接</el-button>
                                </el-input>
                            </div>
                            <el-button plain round size="medium" slot="reference">立即邀请</el-button>
                        </el-popover>
                    </div>
                </div>
            </div>
        </el-card>
        <el-card class="basic-card margin-t-20">
            <div slot="header" class="text-center fonts-18">
                我的邀请记录
                <el-tooltip content="你最多可获得100万字符奖励" placement="top">
                    <svg-icon name="ib-question" class="margin-l-15"></svg-icon>
                </el-tooltip>
            </div>
            <div class="history-wapper">
                <div class="fonts-15 color-warning">已成功邀请{{person}}人，已获得{{traffics}}字符奖励</div>
                <div class="history-list" v-if="list.length">
                    <template v-for="(item, i) in list">
                        <div class="item" :key="i">
                            <div class="name">{{item.phone || item.email}}</div>
                            <div class="desc">使用 {{item.coder}} 邀请码成功注册</div>
                            <div class="time">{{item.createTime}}</div>
                        </div>
                    </template>
                </div>
                <template v-else>
                    <el-empty description="暂无邀请记录"></el-empty>
                </template>
            </div>
            <div class="pagination-content" v-if="list.length">
                <el-pagination 
                    background 
                    @current-change="handleCurrentChange"
                    layout="total, prev, pager, next"
                    :page-size="pageSize"
                    :total="total">
                </el-pagination>
            </div>
        </el-card>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import { userApi } from '@/utils/api';
export default {
    data(){
        return {
            shareLink: '',
            pageNumber: 1,
            pageSize: 10,
            total: 0,
            list: [],
            traffics: 0,
            person: 0,
            inviteCode: '',
        }
    },
    computed: {
		...mapState({
			userInfo: state => state.user.userData,
		}),
	},
    methods: {
        initPage(){
            let url = `${userApi.queryInviteList}?pageNumber=${this.pageNumber}&pageSize=${this.pageSize}`;
            this.$ajax.get(url).then(res => {
                if(res.status === 200){
                    this.list = res.data.pager.records;
                    this.total = res.data.pager.total;
                    this.traffics = res.data.traffics;
                    this.person = res.data.person;
                    this.inviteCode = res.data.coder || '';
                    this.shareLink = `${location.origin}/signup?code=${this.inviteCode}`;
                }
            })

        },
        handleCurrentChange(val){
            this.pageNumber = val;
            this.initPage();
        },
        copyShareLink(){
            let self = this;
            let text = `${this.userInfo.nickname || this.userInfo.username || this.userInfo.phone}邀请你来注册LanguageX，点击我的专属链接注册，可以获得5万字符：${this.shareLink}；邀请码：${this.inviteCode}`;
            this.$copyText(text).then(function (e) {
                self.$message.success('已复制到剪切板');
            }, function (e) {
                self.$message.error('复制到剪切板失败，请重试');
            })
        },
    },
    mounted(){
        this.initPage();
    }

}
</script>
<style lang="scss" scoped>
.invite-page{
    .basic-card{
        box-shadow: initial;
    }
    .banner-card{
        ::v-deep .el-card__body{
            padding: 0;
        }
    }
    .banner-wapper{
        display: flex;
        .banner-img{
            width: 325px;
        }
        .banner-right{
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
    }
    .history-wapper{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .history-list{
            margin-top: 30px;
            width: 500px;
            .item{
                display: flex;
                font-size: 15px;
                line-height: 24px;
                margin-top: 35px;
                &:first-child{
                    margin-top: 0px;
                }
                .name{
                    width: 98px;
                    overflow:hidden;
                    text-overflow:ellipsis; 
                    white-space:nowrap;
                }
                .desc{
                    flex: 1;
                    margin: 0 20px;
                }
                .time{
                    width: 154px;
                }
            }
        }
    }
}

</style>
<style lang="scss">
.invite-popover{
    border-radius: 10px;
    padding: 20px;
    .el-input-group__append{
        background-color:#000000;
        color: #fff;
        border: 1px solid #000000;
    }
}
</style>
